import React from "react";
// Customizable Area Start
import { 
  StyleSheet,
  Image, 
  View, 
  SafeAreaView, 
  ImageBackground,
  StatusBar
} from "react-native";
import { backgroundImage, lochiLogo } from "./assets";
import { responsiveHeight, responsiveWidth } from "react-native-responsive-dimensions";
import Scale from "../../../components/src/Scale";
// Customizable Area End

import SplashscreenController, { Props } from "./SplashscreenController";

import { imgSplash } from "./assets";

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <SafeAreaView style={styles.mainContainer}>
      {/* Customizable Area Start */}
      <StatusBar hidden/>
     <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
      <View style={styles.logoContainer}>
      <Image testID="Image" source={lochiLogo} resizeMode="contain" style={styles.lochiLogo}/>
      </View>
     </ImageBackground>
      {/* Customizable Area End */}
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  lochiLogo:{height:'100%',width:'100%',resizeMode:'contain'},
  logoContainer:{
    backgroundColor:'white',
    height:Scale(150),
    width:Scale(150),
    borderRadius:Scale(30),
    justifyContent:'center',
    alignItems:"center"
  },
  backgroundImage: {
    height: responsiveHeight(100),
    width:responsiveWidth(100),
    resizeMode:"cover",
    justifyContent:'center',
    alignItems:"center"
  },
  mainContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff"
  }
});
// Customizable Area End
