import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Alert } from "react-native";

// Customizable Area Start

export const configJSON = require("./config");

// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailText: string;
  subscribeApiResponse: { message: string };
  unsubscribeApiResponse: { message: string };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MailchimpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  subscribeApiCallId: string = "";
  unsubscribeApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      subscribeApiResponse: { message: "" },
      unsubscribeApiResponse: { message: "" },
      emailText: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.subscribeApiCallId &&
        responseJson !== undefined
      ) {
        Alert.alert("", responseJson.message);
        this.setState({ subscribeApiResponse: responseJson });
      }
      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.unsubscribeApiCallId &&
        responseJson !== undefined
      ) {
        Alert.alert("", responseJson.message);
        this.setState({ unsubscribeApiResponse: responseJson });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  subscribe = () => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.subscribeApiCallId = requestMessage.messageId;

    const httpBody = {
      email: this.state.emailText,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscribeAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribe = () => {
    const headers = {
      "Content-Type": configJSON.ApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unsubscribeApiCallId = requestMessage.messageId;

    const httpBody = {
      email: this.state.emailText,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unsubscribeAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUTApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ emailText: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCapitalize: "none",
    autoCompleteType: "email",
    placeholderTextColor: "#000",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnSubscribeProps = {
    onPress: () => this.subscribe(),
  };

  btnUnsubscribeProps = {
    onPress: () => this.unsubscribe(),
  };

  // Customizable Area End
}
