import React from "react";

// Customizable Area Start
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { Spacer } from "../../../components/src/Spacer";
// Customizable Area End

import MailchimpController, { configJSON, Props } from "./MailchimpController";

export default class Mailchimp extends MailchimpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start
    return (
      <View style={styles.container}>
        <SafeAreaView>
          <Spacer direction="vertical" size={20} />
          <TextInput
            style={styles.textInput}
            testID="txtInputEmail"
            placeholder={configJSON.textInputPlaceHolder}
            {...this.txtInputProps}
          />
          <Spacer direction="vertical" size={20} />
          <TouchableOpacity
            style={styles.subscribe}
            testID={"btnSuscribe"}
            {...this.btnSubscribeProps}
          >
            <Text>{configJSON.subscribeButton}</Text>
          </TouchableOpacity>
          <Spacer direction="vertical" size={20} />
          <TouchableOpacity
            style={styles.unsubscribe}
            testID={"btnUnsuscribe"}
            {...this.btnUnsubscribeProps}
          >
            <Text>{configJSON.unsubscribeButton}</Text>
          </TouchableOpacity>
        </SafeAreaView>
      </View>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: "#ffffffff",
  },
  textInput: {
    borderRadius: 3,
    borderWidth: 2,
    borderColor: "blue",
    padding: 10,
  },
  subscribe: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#90EE90",
  },
  unsubscribe: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#FFCCCB",
  },
});
// Customizable Area End
