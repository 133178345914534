Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.ApiContentType = "application/json";
exports.POSTAPiMethod = "POST";
exports.PUTApiMethod = "PUT";
exports.textInputPlaceHolder = "Enter Email ID";
exports.subscribeAPiEndPoint = "https://lochi1217-305825-ruby.b305825.stage.eastus.az.svc.builder.ai/bx_block_mailchimp/mail_chimps";
exports.unsubscribeAPiEndPoint = "https://lochi1217-305825-ruby.b305825.stage.eastus.az.svc.builder.ai/bx_block_mailchimp/mail_chimps";
exports.subscribeButton = "Suscribe";
exports.unsubscribeButton = "Unsuscribe";
// Customizable Area End
